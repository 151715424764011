import { type UseModalState } from "@clipboard-health/ui-react";
import { IonAlert } from "@ionic/react";

interface SkipScheduleAssessmentProps {
  modalState: UseModalState;
  onSkip: () => void;
}

export function SkipScheduleAssessment(props: SkipScheduleAssessmentProps) {
  const { modalState, onSkip } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <IonAlert
      isOpen={modalIsOpen}
      header="Are you sure?"
      message="The earlier you take the assessment, the faster you can start booking shifts."
      buttons={[
        {
          text: "Go back",
          role: "cancel",
        },
        {
          text: "Skip",
          handler: () => {
            closeModal();
            onSkip();
          },
        },
      ]}
      onDidDismiss={closeModal}
    />
  );
}

import { Text } from "@clipboard-health/ui-react";
import { Box, Button, Dialog, DialogContent } from "@mui/material";

interface ResendScheduledAssessmentTextModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleResend: () => void;
  isResending: boolean;
  countdownIsLoading: boolean;
  secondsLeft: number;
}

export function ResendScheduledAssessmentTextModal(props: ResendScheduledAssessmentTextModalProps) {
  const {
    isModalOpen,
    handleCloseModal,
    handleResend,
    isResending,
    countdownIsLoading,
    secondsLeft,
  } = props;
  return (
    <Dialog open={isModalOpen} aria-labelledby="text-resend-modal" onClose={handleCloseModal}>
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 3, textAlign: "center" }}>
            <Text variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
              This can take up to 3 minutes
            </Text>
            <Text variant="body1">
              Still didn&apos;t receive a text? Tap resend to get another one.
            </Text>
          </Box>
          <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
            <Button
              sx={{
                flex: 1,
                py: 2,
                color: "text.secondary",
                borderRight: 1,
                borderColor: "divider",
                borderRadius: 0,
              }}
              disabled={isResending || countdownIsLoading}
              onClick={() => {
                handleResend();
              }}
            >
              Resend {countdownIsLoading && `(${secondsLeft}s)`}
            </Button>
            <Button
              sx={(theme) => ({
                flex: 1,
                py: 2,
                color: theme.palette.primary.main,
                borderRadius: 0,
              })}
              disabled={isResending}
              onClick={() => {
                handleCloseModal();
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
